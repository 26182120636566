exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-agb-tsx": () => import("./../../../src/pages/agb.tsx" /* webpackChunkName: "component---src-pages-agb-tsx" */),
  "component---src-pages-cart-tsx": () => import("./../../../src/pages/cart.tsx" /* webpackChunkName: "component---src-pages-cart-tsx" */),
  "component---src-pages-datenschutz-tsx": () => import("./../../../src/pages/datenschutz.tsx" /* webpackChunkName: "component---src-pages-datenschutz-tsx" */),
  "component---src-pages-impressum-tsx": () => import("./../../../src/pages/impressum.tsx" /* webpackChunkName: "component---src-pages-impressum-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-search-tsx": () => import("./../../../src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */),
  "component---src-pages-shop-index-tsx": () => import("./../../../src/pages/shop/index.tsx" /* webpackChunkName: "component---src-pages-shop-index-tsx" */),
  "component---src-pages-versand-tsx": () => import("./../../../src/pages/versand.tsx" /* webpackChunkName: "component---src-pages-versand-tsx" */),
  "component---src-pages-widerrufsbelehrung-tsx": () => import("./../../../src/pages/widerrufsbelehrung.tsx" /* webpackChunkName: "component---src-pages-widerrufsbelehrung-tsx" */),
  "component---src-templates-main-category-template-js": () => import("./../../../src/templates/main-category-template.js" /* webpackChunkName: "component---src-templates-main-category-template-js" */),
  "component---src-templates-product-template-js": () => import("./../../../src/templates/product-template.js" /* webpackChunkName: "component---src-templates-product-template-js" */),
  "component---src-templates-product-type-template-js": () => import("./../../../src/templates/product-type-template.js" /* webpackChunkName: "component---src-templates-product-type-template-js" */)
}

