import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { MdClose } from "react-icons/md"

const BannerContainer = styled.div`
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  height: fit-content;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.primary};
`

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  > p {
    color: white;
    font-weight: 700;
    font-size: 1.2rem;
    text-align: center;
    padding: 1rem;
    margin: 0 3rem;

    @media (max-width: 600px) {
      font-size: 1rem;
    }
  }

  > button {
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    background: transparent;
    border: none;
    color: white;
    text-transform: uppercase;
    font-size: 1.2rem;
    font-weight: 700;
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  .underline {
    text-decoration: underline;
  }
`

const BottomBanner = () => {
  const [showBanner, setShowBanner] = useState(false)
  const [storageValue, setStorageValue] = useState("0")

  useEffect(() => {
    if (typeof window !== "undefined") {
      const getStoredValue = window.localStorage.getItem("delivery-banner")
      if (getStoredValue === null) {
        // No value saved until now. We show the banner
        // "1" = show banner, "0" = hide banner
        setStorageValue("1")
        window.localStorage.setItem("delivery-banner", "1")
      } else {
        setStorageValue(getStoredValue)
      }
    }
  }, [])

  useEffect(() => {
    if (storageValue === "1") {
      setShowBanner(true)
    }
  }, [storageValue])

  const handleShowBanner = () => {
    if (typeof window !== "undefined") {
      window.localStorage.setItem("delivery-banner", "0")
      setStorageValue("0")
      setShowBanner(false)
    }
  }

  return showBanner ? (
    <BannerContainer>
      <Wrapper>
        <p>
          Kostenlose Lieferung innerhalb von{" "}
          <span className="underline">Mörfelden-Walldorf</span>!
        </p>
        <button onClick={handleShowBanner}>
          <MdClose color="white" size="24px" />
        </button>
      </Wrapper>
    </BannerContainer>
  ) : null
}

export default BottomBanner
