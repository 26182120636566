import React from "react"
import styled from "styled-components"
import { FaTruck, FaPercent, FaTshirt } from "react-icons/fa"
import { Link } from "gatsby"

import dhlLogo from "../images/dhl-logo.svg"

const FooterContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.primary};
  color: white;
  padding: 4rem 2rem;
`

const IconContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  margin: auto;
  max-width: ${({ theme }) => theme.maxWidth};

  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`

const IconBoxWrapper = styled.div`
  text-align: center;
`

const IconBox = styled.div`
  height: 100px;
  width: 100px;
  border-radius: 50%;
  background: white;
  margin: auto;
  position: relative;
`

const IconBoxContent = styled.span`
  position: absolute;
  /* top: 0;
  left: 0;
  right: 0; */
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.primary};
`

const SiteMapWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.primaryLight};
`

const SiteMapContainer = styled.div`
  max-width: ${({ theme }) => theme.maxWidth};
  padding: 1rem 2rem;
  text-align: center;
  color: white;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: baseline;
  margin-left: auto;
  margin-right: auto;

  .divider {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }

  p {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
  }

  .link-container {
    display: inline-block;
    padding-top: 0.5rem;
  }
`

const StyledLink = styled(Link)`
  text-decoration: none;
  color: white;

  :hover {
    text-decoration: underline;
  }
`

const VStyledLink = styled(StyledLink)`
  display: inline-block;
  margin-top: 1rem;
`

const LogoContainer = styled.div`
  background: ${({ theme }) => theme.colors.primary};
  padding: 4rem 0;
  border-top: 1px solid white;
  text-align: center;

  h3 {
    color: white;
    text-align: center;
    font-weight: 400;
    margin-bottom: 1rem;
  }

  img {
    display: block;
    margin: 0 auto;
  }
`

const Footer: React.FC<{}> = () => {
  return (
    <footer>
      <FooterContainer>
        <IconContainer>
          <IconBoxWrapper>
            <IconBox>
              <IconBoxContent>
                <FaTruck size={36} />
              </IconBoxContent>
            </IconBox>
            <p>Bequem liefern lassen</p>
          </IconBoxWrapper>
          <IconBoxWrapper>
            <IconBox>
              <IconBoxContent>
                <FaPercent size={30} />
              </IconBoxContent>
            </IconBox>
            <p>100% Qualität</p>
          </IconBoxWrapper>
          <IconBoxWrapper>
            <IconBox>
              <IconBoxContent>
                <FaTshirt size={36} />
              </IconBoxContent>
            </IconBox>
            <p>Individueller Druck auf Anfrage</p>
          </IconBoxWrapper>
        </IconContainer>
      </FooterContainer>

      <LogoContainer>
        <h3>Wir versenden mit</h3>
        <img src={dhlLogo} alt="DHL Logo" />
        <VStyledLink to="/versand">Versand & Lieferzeit</VStyledLink>
      </LogoContainer>

      <SiteMapWrapper>
        <SiteMapContainer>
          <div>
            &copy;{` `}
            {new Date().getFullYear()} Hochwerben
          </div>
          <p>
            <span className="link-container">
              <StyledLink to="/impressum">Impressum</StyledLink>
              <span className="divider">|</span>
            </span>
            <span className="link-container">
              <StyledLink to="/datenschutz">Datenschutz</StyledLink>
              <span className="divider">|</span>
            </span>
            <span className="link-container">
              <StyledLink to="/agb">AGB</StyledLink>
              <span className="divider">|</span>
            </span>
            <span className="link-container">
              <StyledLink to="/versand">Versand</StyledLink>
              <span className="divider">|</span>
            </span>
            <span className="link-container">
              <StyledLink to="/widerrufsbelehrung">
                Widerrufsbelehrung
              </StyledLink>
            </span>
          </p>
        </SiteMapContainer>
      </SiteMapWrapper>
    </footer>
  )
}

export default Footer
