import React, { useContext } from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import { FaBars, FaSearch } from "react-icons/fa"
import slugify from "slugify"
import styled from "styled-components"

import { AppContext } from "../context/app-context"
import { StoreContext } from "../context/store-context"

import NavCart from "./NavCart"
import NavLogo from "./NavLogo"
import SideBar, { SearchButton } from "./SideBar"
import { Toast } from "./Toast"

import { links } from "../data/links"
import useViewPort from "../hooks/useViewPort"

export interface NavigationLinks {
  id: string
  title: string
  productType: string
  handle: string
  vendor: string
  metafields: [
    {
      key: string
      value: string
    }
  ]
}

const Navigation = () => {
  const { checkout, loading, didJustAddToCart } = useContext(StoreContext)
  const { toggleSideBar } = useContext(AppContext)

  const shopOwner = process.env.GATSBY_SHOP_OWNER

  const items = checkout ? checkout.lineItems : []

  const quantity = items.reduce((total, item) => {
    return total + item["quantity"]
  }, 0)

  const data = useStaticQuery(graphql`
    {
      allShopifyProduct(
        filter: { metafields: { elemMatch: { key: { eq: "navigation" } } } }
      ) {
        nodes {
          id
          title
          productType
          handle
          metafields {
            key
            value
          }
          vendor
        }
      }
    }
  `)

  const filteredByVendor: NavigationLinks[] =
    data?.allShopifyProduct?.nodes?.filter(
      ({ vendor }: { vendor: string }) => vendor === shopOwner
    )

  // Create main nav links per vendor
  let mainNavLinks: string[] = []

  filteredByVendor.forEach(product => {
    product.metafields.forEach(field => {
      if (field.key === "navigation") {
        if (!mainNavLinks.includes(field.value)) {
          mainNavLinks = [...mainNavLinks, field.value]
        }
      }
    })
  })

  // Add ordering to navLinks
  let orderedLinks: string[] = []

  links.forEach(link => {
    if (mainNavLinks.includes(link.title)) {
      orderedLinks = [...orderedLinks, link.title]
    }
  })

  // Check the window size to display proper nav component
  const { width } = useViewPort()

  const breakpoint = 768

  return (
    <>
      <StyledNav>
        <NavContainer>
          <NavLogo />
          {width > breakpoint && (
            <NavLinks>
              {orderedLinks.map((link, i) => {
                const slugifiedLink = slugify(link, {
                  lower: true,
                  locale: "de",
                })
                return (
                  <NavLink to={`/shop/${slugifiedLink}`} key={i}>
                    {link}
                  </NavLink>
                )
              })}
            </NavLinks>
          )}
          <Icons>
            {width > breakpoint && (
              <SearchButton to="/search">
                <FaSearch color="#a8a8a8" size={24} />
              </SearchButton>
            )}
            <NavCart quantity={quantity} />
          </Icons>
          {width <= breakpoint && (
            <HamburgerButton onClick={toggleSideBar}>
              <FaBars size={32} />
            </HamburgerButton>
          )}
        </NavContainer>
        <Toast show={loading || didJustAddToCart}>
          {!didJustAddToCart ? (
            "Aktualisiere…"
          ) : (
            <>
              Zum Warenkorb hinzugefügt{" "}
              <svg
                width="14"
                height="14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.019 10.492l-2.322-3.17A.796.796 0 013.91 6.304L6.628 9.14a1.056 1.056 0 11-1.61 1.351z"
                  fill="#fff"
                />
                <path
                  d="M5.209 10.693a1.11 1.11 0 01-.105-1.6l5.394-5.88a.757.757 0 011.159.973l-4.855 6.332a1.11 1.11 0 01-1.593.175z"
                  fill="#fff"
                />
                <path
                  d="M5.331 7.806c.272.326.471.543.815.163.345-.38-.108.96-.108.96l-1.123-.363.416-.76z"
                  fill="#fff"
                />
              </svg>
            </>
          )}
        </Toast>
      </StyledNav>
      <SideBar
        categories={orderedLinks}
        products={data.allShopifyProduct.nodes}
      />
    </>
  )
}

const StyledNav = styled.nav`
  position: fixed;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.background};
  z-index: 1;
  filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.08));
`

const NavContainer = styled.div`
  height: calc(50px + 2rem);
  display: flex;
  /* flex-wrap: nowrap => default value := items are not allowed to be stacked into multiple lines. */
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  max-width: ${({ theme }) => theme.maxWidth};
  margin: 0 auto;
  padding: 0 1rem;
  position: relative;

  /* @media (min-width: 1000px) {
    padding: 0 2rem;
  } */
`

const NavLinks = styled.div`
  display: flex;
  align-items: center;
  height: 100%;

  a {
    text-decoration: none;
  }
`

const HamburgerButton = styled.button`
  border: none;
  background: transparent;
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
  /* flex-basis: 100%; */
  text-align: right;
  padding: 0;
  margin-left: 3rem;
`

const NavLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0.5rem;
  color: ${({ theme }) => theme.colors.text};
  font-family: "Oswald", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0.07em;
  transition: 300ms ease-in-out;

  :hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`

const Icons = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  padding-right: 1rem;
`

export default Navigation
