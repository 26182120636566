import React, { useEffect, useRef, useState } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { useSpring, config, animated } from "react-spring"
import slugify from "slugify"

import { AppContext } from "../context/app-context"
import { NavigationLinks } from "./Navigation"

const DropdownContainer = styled.div`
  background: ${({ theme }) => theme.colors.primaryLight};
  text-align: center;
  padding: 2rem 1rem;
  margin-bottom: 2rem;

  a {
    color: hsl(206.8965517241379, 100%, 95%);
    text-decoration: none;
    font-size: 1.2rem;
    display: block;
    margin-bottom: 1rem;
    font-family: ${({ theme }) => theme.fontHeading};
    letter-spacing: 1px;

    :last-child {
      margin-bottom: 0;
    }
  }
`

interface SideBarDropdownProps {
  showDropdown: boolean
  products: NavigationLinks[]
  label: string
}

const SideBarDropdown = ({
  showDropdown,
  products,
  label,
}: SideBarDropdownProps) => {
  const { closeSideBar } = React.useContext(AppContext)
  const [height, setHeight] = useState<number | null>(null)

  const heightRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (showDropdown) {
      if (heightRef !== null && heightRef.current !== null) {
        setHeight(heightRef.current.getBoundingClientRect().height)
        heightRef.current.style.height = `${height}px`
      }
    }
  }, [showDropdown])

  const slideInStyles = useSpring({
    config: { ...config.stiff },
    from: { opacity: 0, height: 0 },
    to: {
      opacity: showDropdown ? 1 : 0,
      height: showDropdown ? height : 0,
    },
  })

  const slugifiedNavItem = slugify(label, {
    lower: true,
    locale: "de",
  })

  return (
    <animated.div style={{ ...slideInStyles, overflow: "hidden" }}>
      <div ref={heightRef}>
        <DropdownContainer>
          {products.map(product => {
            const slugifiedProductType = slugify(product.productType, {
              lower: true,
              locale: "de",
            })

            return (
              <Link
                to={`/shop/${slugifiedNavItem}/${slugifiedProductType}`}
                key={product.id}
                onClick={() => closeSideBar()}
              >
                {product.productType}
              </Link>
            )
          })}
        </DropdownContainer>
      </div>
    </animated.div>
  )
}

export default SideBarDropdown
