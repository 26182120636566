import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { BiShoppingBag } from "react-icons/bi"

const CartIconButton = styled(Link)`
  color: ${({ theme }) => theme.colors.primary};
  text-decoration: none;
  font-weight: 600;
  display: inline-block;
  /* align-items: center; */
  /* flex-basis: 100%; */
  /* justify-content: flex-end; */

  div {
    position: relative;
  }

  span {
    position: absolute;
    bottom: -0.8rem;
    right: -0.8rem;
    background: ${({ theme }) => theme.colors.primary};
    color: white;
    border-radius: 50%;
    width: 1.7rem;
    height: 1.7rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem;
  }

  @media (min-width: 1000px) {
    justify-content: flex-end;
  }
`

const CartIconContainer = styled.div`
  flex-basis: 100%;
  text-align: right;
`

const NavCart = ({ quantity }: { quantity: number }) => {
  return (
    <CartIconContainer>
      <CartIconButton to="/cart">
        <div>
          <BiShoppingBag size={30} />
          {quantity > 0 && <span>{quantity}</span>}
        </div>
      </CartIconButton>
    </CartIconContainer>
  )
}

export default NavCart
