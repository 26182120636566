import React from "react"

const defaultValues = {
  tag: "",
  setFilter: (s: string) => {},
  resetFilter: () => {},
}

export const FilterContext = React.createContext(defaultValues)

export const FilterProvider: React.FC<{}> = ({ children }) => {
  const [tag, setTag] = React.useState(defaultValues.tag)

  const setFilter = (newTag: string) => setTag(newTag)

  const resetFilter = () => setTag("")

  return (
    <FilterContext.Provider value={{ tag, setFilter, resetFilter }}>
      {children}
    </FilterContext.Provider>
  )
}
