import React, { useContext, useEffect, useState } from "react"
import styled from "styled-components"
import { FaCaretUp, FaCaretDown } from "react-icons/fa"

import { AppContext } from "../context/app-context"
import SideBarDropdown from "./SideBarDropdown"
import { NavigationLinks } from "./Navigation"

const SideBarLink = styled.button`
  border: none;
  background: transparent;
  font-family: ${({ theme }) => theme.fontHeading};
  text-transform: uppercase;
  color: hsl(206.8965517241379, 100%, 95%);
  font-size: 1.5rem;
  margin: 2rem auto;
  cursor: pointer;
  display: block;
`

interface SideBarNavItemProps {
  label: string
  products: NavigationLinks[]
}

const SideBarNavItem = ({ label, products }: SideBarNavItemProps) => {
  const [showDropdown, setShowDropdown] = useState(false)
  const { isSideBarOpen } = useContext(AppContext)

  useEffect(() => {
    if (!isSideBarOpen) {
      setShowDropdown(false)
    }
  }, [isSideBarOpen])

  const handleClick = () => {
    setShowDropdown(!showDropdown)
  }

  return (
    <>
      <SideBarLink onClick={handleClick}>
        {label}
        {showDropdown ? (
          <FaCaretUp size={16} style={{ marginLeft: "0.5rem" }} />
        ) : (
          <FaCaretDown size={16} style={{ marginLeft: "0.5rem" }} />
        )}
      </SideBarLink>
      {showDropdown && (
        <SideBarDropdown
          products={products}
          showDropdown={showDropdown}
          label={label}
        />
      )}
    </>
  )
}

export default SideBarNavItem
