import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import { FilterContext } from "../context/filter-context"

const NavLogo: React.FC<{}> = () => {
  const shopOwner = process.env.GATSBY_SHOP_OWNER

  const { resetFilter } = React.useContext(FilterContext)

  return (
    <div>
      <Link to="/" onClick={resetFilter} style={{ flexBasis: "100%" }}>
        {shopOwner === "Rot-Weiss Walldorf" ? (
          <StaticImage
            src="../images/logo.png"
            alt="Rot-Weiss Walldorf Logo"
            width={50}
            height={50}
            placeholder="tracedSVG"
          />
        ) : shopOwner === "TGS Walldorf" ? (
          <StaticImage
            src="../images/logo-tgs.png"
            width={50}
            height={50}
            alt="TGS Walldorf Logo"
            placeholder="tracedSVG"
          />
        ) : shopOwner === "SKV Mörfelden Volleyball" ? (
          <StaticImage
            src="../images/logo-skv-volleyball.png"
            width={80}
            alt="SKV Mörfelden Volleyball"
            placeholder="tracedSVG"
          />
        ) : shopOwner === "KTC" ? (
          <StaticImage
            src="../images/ktc-logo.png"
            width={80}
            height={80}
            alt="KTC Logo"
            placeholder="tracedSVG"
          />
        ) : shopOwner === "DeinSign" ? (
          <StaticImage
            src="../images/dein-sign-logo.png"
            width={80}
            alt="DeinSign Logo"
            placeholder="tracedSVG"
          />
        ) : (
          shopOwner === "TC Grün-Weiss Walldorf" && (
            <StaticImage
              src="../images/logo-tcgw.png"
              width={55}
              height={55}
              alt="Logo Tennis Club Grün-Weiss Walldorf"
              placeholder="tracedSVG"
            />
          )
        )}
      </Link>
    </div>
  )
}

export default NavLogo
