import { useEffect, useState } from "react"

const useViewPort = () => {
  const [width, setWidth] = useState(0)

  useEffect(() => {
    // Check SSR
    if (typeof window !== "undefined") {
      setWidth(window.innerWidth)
      const handleWindowResize = () => setWidth(window.innerWidth)
      window.addEventListener("resize", handleWindowResize)

      return () => {
        window.removeEventListener("resize", handleWindowResize)
      }
    }
  }, [])

  return { width }
}

export default useViewPort
