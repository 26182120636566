import React from "react"

const defaultValues = {
  isSideBarOpen: false,
  openSideBar: () => {},
  closeSideBar: () => {},
  toggleSideBar: () => {},
}

export const AppContext = React.createContext(defaultValues)

export const AppProvider: React.FC<{}> = ({ children }) => {
  const [isSideBarOpen, setIsSideBarOpen] = React.useState(
    defaultValues.isSideBarOpen
  )

  const openSideBar = () => setIsSideBarOpen(true)

  const closeSideBar = () => setIsSideBarOpen(false)

  const toggleSideBar = () => setIsSideBarOpen(!isSideBarOpen)

  return (
    <AppContext.Provider
      value={{
        isSideBarOpen,
        openSideBar,
        closeSideBar,
        toggleSideBar,
      }}
    >
      {children}
    </AppContext.Provider>
  )
}
