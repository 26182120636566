import React from "react"
import { AppProvider } from "./src/context/app-context"
import { StoreProvider } from "./src/context/store-context"
import { FilterProvider } from "./src/context/filter-context"

import Layout from "./src/components/Layout"

export const wrapRootElement = ({ element }) => (
  <AppProvider>
    <StoreProvider>
      <FilterProvider>{element}</FilterProvider>
    </StoreProvider>
  </AppProvider>
)

export const wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>
}
