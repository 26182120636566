import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import onClickOutside from "react-onclickoutside"
import { MdOutlineClose } from "react-icons/md"
import { FaSearch } from "react-icons/fa"

import { AppContext } from "../context/app-context"
import SideBarNavItem from "./SideBarNavItem"
import { NavigationLinks } from "./Navigation"

interface SideBarProps {
  categories: string[]
  products: NavigationLinks[]
}

const SideBar = ({ categories, products }: SideBarProps) => {
  const { isSideBarOpen, closeSideBar } = React.useContext(AppContext)

  // @ts-ignore
  SideBar.handleClickOutside = () => closeSideBar()

  // Filter to match vendor
  const vendorProducts = products.filter(
    product => product.vendor === process.env.GATSBY_SHOP_OWNER
  )

  return (
    <SideBarContainer isSideBarOpen={isSideBarOpen}>
      <div>
        <CloseButton onClick={closeSideBar}>
          <MdOutlineClose size={30} />
        </CloseButton>
      </div>
      <div>
        {categories.map(category => {
          let productsInCategory: NavigationLinks[] = []
          let productTypes: string[] = []
          vendorProducts.forEach(product => {
            product.metafields.forEach(field => {
              if (field.key === "navigation") {
                if (field.value === category) {
                  if (!productTypes.includes(product.productType)) {
                    productTypes = [...productTypes, product.productType]
                    productsInCategory = [...productsInCategory, product]
                  }
                }
              }
            })
          })

          return (
            <SideBarNavItem
              key={category}
              label={category}
              products={productsInCategory}
            />
          )
        })}
      </div>

      <div>
        <SearchButton to="/search" onClick={() => closeSideBar()}>
          Suche <FaSearch />
        </SearchButton>
      </div>
    </SideBarContainer>
  )
}

const clickOutsideConfig = {
  // @ts-ignore
  handleClickOutside: () => SideBar.handleClickOutside,
}

interface ContainerProps {
  isSideBarOpen: boolean
}

const SideBarContainer = styled.aside<ContainerProps>`
  z-index: 1000;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.primary};
  transition: all 0.3s ease-in-out;
  transform: ${({ isSideBarOpen }) =>
    isSideBarOpen ? `translateX(0)` : `translateX(100%)`};

  overflow-y: scroll;
  overflow-x: hidden;

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  @media screen and (min-width: 678px) {
    width: 400px;
  }
`

const CloseButton = styled.button`
  border: none;
  background: transparent;
  color: white;
  margin: 1rem;
  cursor: pointer;
`

export const SearchButton = styled(Link)`
  font-family: ${({ theme }) => theme.fontHeading};
  text-transform: uppercase;
  color: hsl(206.8965517241379, 100%, 95%);
  font-size: 1.5rem;
  margin: 2rem auto;
  cursor: pointer;
  display: block;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    margin-left: 0.5rem;
  }
`

export default onClickOutside(SideBar, clickOutsideConfig)
