import React from "react"
import styled from "styled-components"

const ToastWrapper = styled.div`
  .toastWrapper {
    background-color: ${({ theme }) => theme.green};
    color: white;
    width: max-content;
    position: absolute;
    right: 0;
    bottom: 0;
    padding: 0.5rem 1rem;
    font-size: 12px;
    font-weight: 600;
    border-radius: 999px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    min-width: 100px;
    justify-content: center;
    z-index: 10;
    white-space: nowrap;
  }

  @keyframes showing {
    0% {
      transform: translateX(200px);
      opacity: 0;
    }
    50% {
      transform: translateX(0);
      opacity: 1;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }

  @keyframes hiding {
    50% {
      transform: translateX(200px);
      opacity: 0;
    }
    100% {
      transform: translateX(200px);
      opacity: 0;
    }
  }

  .hiding {
    animation: hiding 1s ease;
  }

  .showing {
    animation: showing 1s ease-out;
  }
`

interface ToastProps {
  show: boolean
  duration?: number
  className?: string
  [x: string]: any
}

export const Toast = ({
  show,
  duration = 1000,
  className,
  ...props
}: ToastProps) => {
  const [visible, setVisible] = React.useState(show)
  const [animation, setAnimation] = React.useState("")

  React.useEffect(() => {
    if (show) {
      setVisible(true)
    }
    const timeout = setTimeout(() => {
      setAnimation("")
      setVisible(show)
    }, duration)
    setAnimation(show ? "showing" : "hiding")
    return () => clearTimeout(timeout)
  }, [show, duration])

  return visible ? (
    <ToastWrapper>
      <div
        className={["toastWrapper", animation, className].join(" ")}
        {...props}
      />
    </ToastWrapper>
  ) : null
}
