export const links = [
  {
    id: 1,
    title: "Herren",
  },
  {
    id: 2,
    title: "Damen",
  },
  {
    id: 3,
    title: "Jugend",
  },
  {
    id: 4,
    title: "Kids",
  },
  {
    id: 5,
    title: "Accessoires",
  },
]
