import React, { useState, createContext, useEffect } from "react"
import fetch from "isomorphic-fetch"
import Client from "shopify-buy"

const client = Client.buildClient(
  {
    domain: process.env.GATSBY_SHOPIFY_STORE_URL!,
    storefrontAccessToken: process.env.GATSBY_STOREFRONT_ACCESS_TOKEN!,
  },
  // @ts-ignore
  fetch
)

const defaultValues = {
  cart: [],
  isOpen: false,
  loading: false,
  onOpen: () => {},
  onClose: () => {},
  addVariantToCart: () => {},
  removeLineItem: () => {},
  updateLineItem: () => {},
  client,
  checkout: {
    lineItems: [],
  },
  didJustAddToCart: false,
}

export const StoreContext = createContext(defaultValues)

const isBrowser = typeof window !== "undefined"
const localStorageKey = "shopify_checkout_id"

export const StoreProvider: React.FC<{}> = ({ children }) => {
  const [checkout, setCheckout] = useState(defaultValues.checkout)
  const [loading, setLoading] = useState(false)
  const [didJustAddToCart, setDidJustAddToCart] = useState(false)

  const setCheckoutItem = (checkout: any) => {
    if (isBrowser) {
      localStorage.setItem(localStorageKey, checkout.id)
    }

    setCheckout(checkout)
  }

  useEffect(() => {
    const initializeCheckout = async () => {
      const existingCheckoutId = isBrowser
        ? localStorage.getItem(localStorageKey)
        : null

      if (existingCheckoutId && existingCheckoutId !== "null") {
        try {
          const existingCheckout = await client.checkout.fetch(
            existingCheckoutId
          )
          if (!existingCheckout.completedAt) {
            setCheckoutItem(existingCheckout)
            return
          }
        } catch (e) {
          localStorage.setItem(localStorageKey, "null")
        }
      }

      const newCheckout = await client.checkout.create()
      setCheckoutItem(newCheckout)
    }

    initializeCheckout()
  }, [])

  const addVariantToCart = async (
    variantId: string,
    quantity: string,
    customTextFront: string,
    customTextBack: string,
    selectedFont: string
  ) => {
    setLoading(true)

    const checkoutId = checkout.id

    // Check if front text or back text is set. If not, don't send custom attributes at all.
    let cAttribs: Array<{
      key: string
      value: string
    }> = []

    if (customTextFront !== "" || customTextBack !== "") {
      cAttribs = [
        { key: "Text-Front", value: customTextFront },
        { key: "Text-Rückseite", value: customTextBack },
        { key: "Schriftart", value: selectedFont },
      ]
    }

    const lineItemsToUpdate = [
      {
        variantId,
        quantity: parseInt(quantity, 10),
        customAttributes: cAttribs,
      },
    ]

    return client.checkout
      .addLineItems(checkoutId, lineItemsToUpdate)
      .then(res => {
        setCheckout(res)
        setLoading(false)
        setDidJustAddToCart(true)
        setTimeout(() => setDidJustAddToCart(false), 3000)
      })
  }

  const removeLineItem = (checkoutId: string, lineItemId: string) => {
    setLoading(true)

    return client.checkout
      .removeLineItems(checkoutId, [lineItemId])
      .then(res => {
        setCheckout(res)
        setLoading(false)
      })
  }

  const updateLineItem = (
    checkoutId: string,
    lineItemId: string,
    quantity: string
  ) => {
    setLoading(true)

    const lineItemsToUpdate = [
      { id: lineItemId, quantity: parseInt(quantity, 10) },
    ]

    return client.checkout
      .updateLineItems(checkoutId, lineItemsToUpdate)
      .then(res => {
        setCheckout(res)
        setLoading(false)
      })
  }

  return (
    <StoreContext.Provider
      value={{
        ...defaultValues,
        addVariantToCart,
        removeLineItem,
        updateLineItem,
        checkout,
        loading,
        didJustAddToCart,
      }}
    >
      {children}
    </StoreContext.Provider>
  )
}
